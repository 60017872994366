import axios from "axios";
import { CheckType } from "../models/CheckType";
import apiUrl from "./apiConnector";
import {
  DefaultDetailedStatistics,
  DetailedStatistics,
} from "../models/DetailedStatistics";

export const getAllCheckTypes = async (): Promise<CheckType[]> => {
  let response = await axios.get<CheckType[]>(`${apiUrl}/check-types`);
  return response.status != 200 ? [] : response.data;
};

export const getAllStatistics = async (
  fromDate: Date
): Promise<DetailedStatistics> => {
  let response = await axios.get<DetailedStatistics>(
    `${apiUrl}/statistics?startDate=${fromDate.toUTCString()}`
  );
  return response.status == 200 ? response.data : DefaultDetailedStatistics;
};
