import { Chart } from "primereact/chart";

interface SimpleStatusCardProps {
  title: string;
  description: string;
  isActive: boolean;
  responseTimeList: number[];
}

const SimpleStatusCard = ({
  title,
  description,
  isActive,
  responseTimeList,
}: SimpleStatusCardProps) => {
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: Array.from(
      { length: responseTimeList.length },
      (_, index) => index + 1
    ),
    datasets: [
      {
        data: responseTimeList,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const cardStatus = isActive
    ? `card green-background border-round-xl h-8rem scalein shadow-1`
    : `card red-background border-round-xl h-8rem scalein shadow-1`;

  return (
    <div className={cardStatus}>
      <div className="w-full h-full opacity-10">
        <Chart
          className="w-full h-full"
          type="line"
          data={data}
          options={options}
        />
      </div>
      <div className="relative font-bold p-3" style={{ top: "-8rem" }}>
        <p className="m-0 text-2xl">{title}</p>
        <p className="mt-4">{description}</p>
      </div>
    </div>
  );
};

export default SimpleStatusCard;
