import Logo from "../components/Logo";
import SimpleStatusPage from "./SimpleStatusPage";

const Layout = () => {
  return (
    <div className="flex flex-column">
      <div className="flex-grow-1">
        <div className="flex">
          <div className="w-2"></div>
          <div className="w-8 flex flex-column gap-2 pt-4">
            <div className="flex h-4rem justify-content-center align-items-center scalein">
              <Logo />
            </div>
            <SimpleStatusPage />
          </div>
          <div className="w-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
