import { useEffect, useState } from "react";
import SimpleStatusCard from "../components/SimpleStatusCard";
import { getAllStatistics } from "../services/apiService";
import {
  DefaultDetailedStatistics,
  DetailedStatistics,
} from "../models/DetailedStatistics";

const SimpleStatusPage = () => {
  const [fromDate] = useState<Date>(new Date(Date.now() - 3600000));
  const [stats, setStats] = useState<DetailedStatistics>(
    DefaultDetailedStatistics
  );

  useEffect(() => {
    getAllStatistics(fromDate).then((details) => {
      setStats(details);
    });
  }, []);

  return (
    <>
      <div className="flex flex-wrap gap-4 scalein">
        <div className="flex align-items-center">
          <div className="dot green-background mr-2 h-1rem w-1rem" />
          Servicii accesibile
        </div>
        <div className="flex align-items-center">
          <div className="dot red-background mr-2 h-1rem w-1rem" />
          Servicii neaccesibile
        </div>
      </div>
      <div className="grid">
        {stats.stats
          .sort((a, b) => Number(b.isRunning) - Number(a.isRunning))
          .map((stat) => (
            <div key={stat.title} className="col-12 lg:col-6">
              <SimpleStatusCard
                title={stat.title}
                description={stat.description}
                isActive={stat.isRunning}
                responseTimeList={stat.responseTimes}
              />
            </div>
          ))}
      </div>
      <div className="flex flex-column align-items-center scalein font-italic">
        <p style={{ height: "0.5rem" }} className="mt-0 text-sm">
          Site-ul nu apartine de ANAF.
        </p>
        <p style={{ height: "0.5rem" }} className="mt-0 text-sm mr-1">
          Data ultimei actualizari:
        </p>
        <p style={{ height: "0.5rem" }} className="mt-0 text-sm">
          {new Date(stats.lastUpdateDate).toLocaleDateString() +
            " " +
            new Date(stats.lastUpdateDate).toLocaleTimeString()}
        </p>
      </div>
    </>
  );
};

export default SimpleStatusPage;
