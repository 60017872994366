import { TypeStatistics } from "./TypeStatistics";

export type DetailedStatistics = {
  stats: TypeStatistics[];
  lastUpdateDate: string;
};

export const DefaultDetailedStatistics: DetailedStatistics = {
  stats: [],
  lastUpdateDate: new Date(new Date().getUTCDate()).toDateString(),
};
